import * as Yup from 'yup';

export const SMSInfobipFormSchema = Yup.object().shape({
  activatedGroup: Yup.array(),
});

export type TSMSInfobipConfigFormSchema = Yup.InferType<typeof SMSInfobipFormSchema>;

export const SMSInfobipFormSchemaDefaultValues: TSMSInfobipConfigFormSchema = {
  activatedGroup: [],
};
