import { m, AnimatePresence } from 'framer-motion';

import Stack from '@mui/material/Stack';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useTheme } from '@mui/material/styles';
import { Paper, PaperProps } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { bgGradient } from 'src/theme/css';
import ZekiWarning from 'src/assets/illustrations/zeki-warning';

import getVariant from 'src/sections/_examples/extra/animate-view/get-variant';

import { ConfirmDialogLargeButtonsProps } from './types';

// ----------------------------------------------------------------------

function ConfirmDialogLargeButtons({
  title,
  content,
  action,
  onClose,
  showZekiIllustration = false,
  illustration,
  children,
  open,
  submitButton,
  selectAnimationVariant,
}: ConfirmDialogLargeButtonsProps) {
  const theme = useTheme();

  const renderIllustration = (
    <Stack
      flexGrow={1}
      sx={{
        height: { xs: '10rem', md: null },
        alignItems: 'center',
        justifyContent: 'center',
        py: { xs: 2, md: 4 },
        ...bgGradient({
          startColor: theme.palette.secondary.main,
          endColor: theme.palette.primary.main,
          direction: '311.67deg',
        }),
      }}
    >
      <ZekiWarning />
    </Stack>
  );

  const defaultAction = (
    <>
      <Button size="large" fullWidth variant="outlined" color="inherit" onClick={onClose}>
        Fechar
      </Button>
      <LoadingButton
        loading={submitButton?.isLoading}
        size="large"
        fullWidth
        variant="contained"
        color={submitButton?.color || 'primary'}
        onClick={submitButton?.onClick}
        type="submit"
      >
        {submitButton?.label || 'Confirmar'}
      </LoadingButton>
    </>
  );

  const dialogContent = (
    <>
      {showZekiIllustration && !illustration && renderIllustration}
      {illustration}
      {title && <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>}
      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}
      {children}
      <DialogActions>{action || defaultAction}</DialogActions>
    </>
  );

  const animatedContent = (
    <m.div
      {...getVariant(selectAnimationVariant)}
      transition={{ duration: 0.3, delay: 0.1 }}
      style={{ backgroundColor: 'white', borderRadius: 20, overflow: 'hidden' }}
    >
      {dialogContent}
    </m.div>
  );

  return (
    <AnimatePresence>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={onClose}
        PaperComponent={(props: PaperProps) =>
          selectAnimationVariant ? animatedContent : <Paper {...props}>{dialogContent}</Paper>
        }
      />
    </AnimatePresence>
  );
}

export default ConfirmDialogLargeButtons;
