import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import { Stack } from '@mui/system';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import {
  WhatsappConfigType,
  SaveWhatsappIntegrationMutationVariables as TVariables,
  useSaveWhatsappIntegrationMutation as useSaveWhatsappIntegration,
} from 'src/graphql/generated';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSwitch, RHFTextField } from 'src/components/hook-form';

import { WhatsappConfigFormSchema, whatsappFormSchemaDefaultValues } from '../whatsapp-form-schema';

// ----------------------------------------------------------------------

type TAttributes =
  | 'whatsappAccessToken'
  | 'whatsappAppBusinessId'
  | 'whatsappBusinessAccountId'
  | 'activated'
  | 'phoneNumberId';

type TCurrentData = Pick<WhatsappConfigType, TAttributes>;

type Props = {
  open: boolean;
  onClose: () => void;
  disableForm: boolean;
  currentData?: TCurrentData | null;
  refetch: () => Promise<ApolloQueryResult<any>>;
  hasData: boolean;
};

// ----------------------------------------------------------------------

export function ConfigWhatsappModal({
  open,
  onClose,
  currentData,
  refetch,
  disableForm,
  hasData,
}: Props) {
  const [enableForm, setEnableForm] = useState<boolean>();

  const [saveWhatsappIntegration] = useSaveWhatsappIntegration();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(WhatsappConfigFormSchema),
    defaultValues: whatsappFormSchemaDefaultValues,
  });

  const {
    handleSubmit,
    reset,
    watch,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const variables: TVariables = {
      input: {
        phoneNumberId: data.phoneNumberId,
        whatsappAccessToken: data.whatsappAccessToken,
        whatsappAppBusinessId: data?.whatsappAppBusinessId,
        whatsappBusinessAccountId: data?.whatsappBusinessAccountId,
        activated: data?.activated,
      },
    };

    try {
      await saveWhatsappIntegration({ variables });

      enqueueSnackbar('Dados atualizados!', { variant: 'success' });

      onClose();

      handleClose();

      await refetch();
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar os dados!', { variant: 'error' });
    }
  });

  const handleClose = () => {
    onClose();
    reset();
    setEnableForm(!disableForm);
  };

  useEffect(() => {
    const updateFields = () => {
      if (!currentData || !hasData) return;

      reset({
        whatsappAppBusinessId: currentData?.whatsappAppBusinessId || '',
        whatsappBusinessAccountId: currentData?.whatsappBusinessAccountId || '',
        whatsappAccessToken: currentData?.whatsappAccessToken || '',
        phoneNumberId: currentData?.phoneNumberId || '',
        activated: currentData?.activated,
      });
    };

    updateFields();
  }, [currentData, reset, hasData]);

  useEffect(() => {
    setEnableForm(!disableForm);
  }, [disableForm]);

  return (
    <FormProvider methods={methods}>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: { xs: '90%', md: 600 },
          },
        }}
      >
        <DialogTitle textAlign="center" fontSize={20}>
          WhatsApp Business
        </DialogTitle>

        <DialogContent
          sx={{
            width: '100%',
            minHeight: 180,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
            marginBottom: 2,
            py: 2,
          }}
        >
          <RHFTextField name="whatsappAccessToken" label="Token de Acesso" disabled={!enableForm} />

          <RHFTextField
            name="phoneNumberId"
            label="ID do Número de Telefone"
            disabled={!enableForm}
          />

          <RHFTextField
            name="whatsappBusinessAccountId"
            label="ID da Conta Business"
            disabled={!enableForm}
          />

          <RHFTextField
            name="whatsappAppBusinessId"
            label="ID do Aplicativo"
            disabled={!enableForm}
          />
        </DialogContent>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <DialogActions
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <RHFSwitch
            name="activated"
            label={watch('activated') ? 'Serviço Ativo' : 'Serviço Inativo'}
            disabled={!enableForm}
          />
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Fechar
            </Button>

            {enableForm && (
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isSubmitting}
                onClick={onSubmit}
              >
                Salvar
              </LoadingButton>
            )}

            {!enableForm && (
              <Button variant="contained" color="primary" onClick={() => setEnableForm(true)}>
                {currentData && hasData ? 'Editar' : 'Criar'}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
