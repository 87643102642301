import { useState } from 'react';
import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

// import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useAuthContext } from 'src/auth/hooks';
import { CustomerGender, GetProfileQuery } from 'src/graphql/generated';

import { varHover } from 'src/components/animate';
import { useSnackbar } from 'src/components/snackbar';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

import { AccountForm } from './account-form';

// ----------------------------------------------------------------------

const AVATAR_IMAGE_URL: { [K in CustomerGender]: string } = {
  MALE: '/avatar/avatar-masculino.png',
  FEMALE: '/avatar/avatar-feminino.png',
  NOT_INFORMED: '/avatar/avatar-nao-informado.png',
};

// ----------------------------------------------------------------------

export default function AccountPopover({ profile }: { profile: GetProfileQuery }) {
  const [openResetModal, setOpenResetModal] = useState<boolean>(false);
  const theme = useTheme();

  const router = useRouter();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const popover = usePopover();

  const urlPhoto = profile?.me ? AVATAR_IMAGE_URL[profile?.me?.gender!] : '';

  const options = [
    // {
    //   label: 'Perfil',
    //   linkTo: paths.dashboard.user.profile,
    // },
    {
      label: 'Redefinir Senha',
      onClick: () => setOpenResetModal(true),
    },
    {
      label: 'Central de Ajuda',
      onClick: () => window.open('https://zeki.freshdesk.com/support/home'),
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      popover.onClose();
      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout!', { variant: 'error' });
    }
  };

  // const handleClickItem = (path: string) => {
  //   popover.onClose();
  //   router.push(path);
  // };

  if (profile && user) {
    user.id = profile?.me?.pk;
    user.email = profile?.me?.email;
    user.name = `${profile?.me?.firstName} ${profile?.me?.lastName}`;
  }

  return (
    <>
      <IconButton
        component={m.button}
        whileTap="tap"
        whileHover="hover"
        variants={varHover(1.05)}
        onClick={popover.onOpen}
        sx={{
          width: 40,
          height: 40,
          background: alpha(theme.palette.grey[500], 0.08),
          ...(popover.open && {
            background: `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
          }),
        }}
      >
        {urlPhoto && (
          <Avatar
            src={urlPhoto}
            alt={user?.displayName}
            sx={{
              width: 36,
              height: 36,
              border: `solid 2px ${theme.palette.background.default}`,
            }}
          >
            {user?.name?.charAt(0).toUpperCase()}
          </Avatar>
        )}
      </IconButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
        <Box sx={{ p: 2, pb: 1.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.name}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Stack sx={{ p: 1 }}>
          {options.map((option) => {
            let props = {
              key: option.label,
              onClick: () => handleClickItem(option.linkTo!),
            };

            if (option.onClick) {
              props = {
                key: option.label,
                onClick: () => {
                  popover.onClose();

                  option.onClick();
                },
              };
            }
            return <MenuItem {...props}>{option.label}</MenuItem>;
          })}
        </Stack> */}

        <Stack sx={{ p: 1 }}>
          {options.map((option) => {
            const props = {
              key: option.label,
              onClick: () => {
                popover.onClose();
                if (option.onClick) {
                  option.onClick();
                }
              },
            };
            return <MenuItem {...props}>{option.label}</MenuItem>;
          })}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={handleLogout}
          sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
        >
          Sair
        </MenuItem>
      </CustomPopover>

      <AccountForm openResetModal={openResetModal} onClose={() => setOpenResetModal(false)} />
    </>
  );
}
