import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import { IconButton, InputAdornment } from '@mui/material';

import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks';
import {
  useUpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables as TVariables,
} from 'src/graphql/generated';

import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { ConfirmDialogLargeButtons } from 'src/components/custom-dialog';

import {
  ResetPasswordFormSchema,
  resetPasswordSchemaDefaultValues as defaultValues,
} from './account-schema';

// ----------------------------------------------------------------------

type AccountFormProps = {
  openResetModal: boolean;
  onClose: () => void;
};

// ----------------------------------------------------------------------

export function AccountForm({ openResetModal, onClose }: AccountFormProps) {
  const theme = useTheme();
  const router = useRouter();

  const { enqueueSnackbar } = useSnackbar();
  const { user, logout } = useAuthContext();

  const [updateUserPassword] = useUpdateUserPasswordMutation();

  const currentPassword = useBoolean();
  const newPassword = useBoolean();
  const confirmPassword = useBoolean();

  const methods = useForm({
    resolver: yupResolver(ResetPasswordFormSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const handleLogout = async () => {
    try {
      await logout();

      router.replace('/');
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Não é possível sair!', { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const variables: TVariables = {
        input: {
          oldPassword: data.currentPassword,
          newPassword: data.newPassword,
          newPasswordConfirm: data.confirmPassword,
          userId: user?.id as string,
        },
      };

      const response = await updateUserPassword({ variables });

      const statusResponse = response?.data?.redefinePassword?.ok as Boolean;

      if (statusResponse) {
        enqueueSnackbar('Senha atualizada!', {
          variant: 'success',
        });

        onClose();

        reset();

        await handleLogout();
      }

      // const errors = response?.data?.passwordChange?.errors!;
      // const errorKey = Object.keys(errors)[0]!;
      // const errorMessage = errors[errorKey][0]?.message!;

      // throw new Error(errorMessage);
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, {
          variant: 'error',
        });

        return;
      }

      enqueueSnackbar('Não foi possível atualizar a senha!', {
        variant: 'error',
      });
    }
  });

  return (
    <FormProvider methods={methods}>
      <ConfirmDialogLargeButtons
        open={openResetModal}
        onClose={() => {
          onClose();
          reset();
        }}
        action={
          <>
            <Button
              size="large"
              fullWidth
              variant="outlined"
              color="inherit"
              onClick={() => {
                onClose();
                reset();
              }}
            >
              Fechar
            </Button>
            <LoadingButton
              size="large"
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Salvar
            </LoadingButton>
          </>
        }
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '1.5rem',
            width: '100%',
            paddingTop: '40px',
          }}
        >
          <Iconify
            icon="ic:round-vpn-key"
            sx={{ minWidth: '96px', minHeight: '96px', color: theme.palette.info.main }}
          />

          <Typography variant="h3">Redefinir Senha</Typography>

          <RHFTextField
            name="currentPassword"
            label="Senha Atual"
            type={currentPassword.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={currentPassword.onToggle} edge="end">
                    <Iconify
                      icon={currentPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            name="newPassword"
            label="Nova Senha"
            type={newPassword.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={newPassword.onToggle} edge="end">
                    <Iconify
                      icon={newPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <RHFTextField
            name="confirmPassword"
            label="Confirmar Senha"
            type={confirmPassword.value ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={confirmPassword.onToggle} edge="end">
                    <Iconify
                      icon={confirmPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
      </ConfirmDialogLargeButtons>
    </FormProvider>
  );
}
