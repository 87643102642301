import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { ApolloQueryResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useBoolean } from 'src/hooks/use-boolean';

import {
  SmsInfoBipIntegrationType,
  useSmsInfobipIntegrationMutation,
  MutationSetSmsInfoBipIntegrationArgs,
} from 'src/graphql/generated';

import { RHFMultiCheckbox } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import { SMSInfobipFormSchema, SMSInfobipFormSchemaDefaultValues } from '../smsinfobip-form-schema';

// ----------------------------------------------------------------------

type TCurrentData = Pick<SmsInfoBipIntegrationType, 'activated'>;

type Props = {
  open: boolean;
  onClose: () => void;
  currentData?: TCurrentData | null;
  refetch: () => Promise<ApolloQueryResult<any>>;
};

// ----------------------------------------------------------------------

export function ConfigSMSInfobipModal({ open, onClose, currentData, refetch }: Props) {
  const enableForm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const [setSmsInfobipIntegration] = useSmsInfobipIntegrationMutation();

  const methods = useForm({
    resolver: yupResolver(SMSInfobipFormSchema),
    defaultValues: SMSInfobipFormSchemaDefaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClose = () => {
    if (!currentData) enableForm.onTrue();
    else enableForm.onFalse();

    onClose();
    reset();
  };

  const onSubmit = handleSubmit(async (data) => {
    let label = 'Cadastro efetuado com sucesso!';

    const variables: MutationSetSmsInfoBipIntegrationArgs = {
      input: {
        activated: data.activatedGroup?.includes('activated'),
      },
    };

    if (currentData) label = 'Dados atualizados com sucesso!';

    try {
      await setSmsInfobipIntegration({ variables });

      enqueueSnackbar(label, { variant: 'success' });

      await refetch();

      enableForm.onFalse();
      onClose();
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o dados!', { variant: 'error' });
    }
  });

  useEffect(() => {
    const updateFields = () => {
      if (!currentData) return;

      reset({
        activatedGroup: [currentData.activated && 'activated'].filter(Boolean),
      });
    };

    updateFields();
  }, [currentData, reset]);

  useEffect(() => {
    if (!currentData) {
      enableForm.onTrue();

      return;
    }

    enableForm.onFalse();
    // eslint-disable-next-line
  }, [currentData]);

  return (
    <FormProvider methods={methods}>
      <Dialog open={open}>
        <DialogTitle textAlign="center" fontSize={20}>
          Integração SMS
        </DialogTitle>

        <DialogContent
          sx={{
            minWidth: { xs: 400, md: 400 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <RHFMultiCheckbox
            name="activatedGroup"
            options={[{ value: 'activated', label: 'Ativar integração SMS' }]}
            disabled={!enableForm}
          />
        </DialogContent>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Fechar
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            type="submit"
            loading={isSubmitting}
            onClick={onSubmit}
          >
            Salvar
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
