import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';

type Props = DatePickerProps<Date> & {
  name: string;
  error?: boolean;
  helperText?: string;
};

function RHFDatePicker({ name, helperText, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          inputRef={field.ref}
          slotProps={{
            textField: {
              helperText: error ? error?.message : helperText,
              error: !!error,
              variant: other.disabled ? 'filled' : 'outlined',
            },
          }}
          {...other}
        />
      )}
    />
  );
}

export default memo(RHFDatePicker);
