import Box from '@mui/material/Box';
import { Stack } from '@mui/system';
import { Button, Typography } from '@mui/material';

import ZekiWarning from 'src/assets/illustrations/zeki-warning';

const DisconnectedStoreComponent = () => {
  const renderIllustration = (
    <Stack
      flexGrow={1}
      sx={{
        height: { xs: '11rem', md: '20rem' },
        alignItems: 'center',
        justifyContent: 'center',
        py: { xs: 2, md: 4 },
      }}
    >
      <ZekiWarning />
    </Stack>
  );

  return (
    <Box
      height={{ xs: '80vh', md: '100%' }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={{ md: 2 }}
    >
      <Stack
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap={{ xs: 5, md: 3 }}
        width="100%"
      >
        <Typography variant="h3" color="GrayText">
          Loja Desativada
        </Typography>

        {renderIllustration}

        <Typography sx={{ maxWidth: 280, textAlign: 'center' }}>
          Você não pode acessar esta loja no momento, pois ela está desativada.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open('https://zeki.freshdesk.com/support/tickets/new', '_blank')}
        >
          Fale com o Suporte
        </Button>
      </Stack>
    </Box>
  );
};

export default DisconnectedStoreComponent;
