import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import {
  OmieStoreIntegrationType,
  useOmieIntegrationMutation,
  OmieIntegrationMutationVariables as TVariables,
} from 'src/graphql/generated';

import FormProvider from 'src/components/hook-form/form-provider';
import { RHFSwitch, RHFTextField, RHFMultiCheckbox } from 'src/components/hook-form';

import { OmieConfigFormSchema, omieFormSchemaDefaultValues } from '../omie-form-schema';

// ----------------------------------------------------------------------

type TAttributes = 'appKey' | 'appSecret' | 'nfce' | 'nfe' | 'nfse' | 'activated';

type TCurrentData = Pick<OmieStoreIntegrationType, TAttributes>;

type Props = {
  open: boolean;
  onClose: () => void;
  disableForm?: boolean;
  currentData?: TCurrentData | null;
  refetch: () => Promise<ApolloQueryResult<any>>;
};

// ----------------------------------------------------------------------

export function ConfigOmieModal({
  open,
  onClose,
  currentData,
  disableForm = false,
  refetch,
}: Props) {
  const [enableForm, setEnableForm] = useState<boolean>(!disableForm);
  const [createOmieIntegration] = useOmieIntegrationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(OmieConfigFormSchema),
    defaultValues: omieFormSchemaDefaultValues,
  });

  const watch = useWatch({ control: methods.control });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const handleClose = () => {
    onClose();
    reset();
    setEnableForm(!disableForm);
  };

  const onSubmit = handleSubmit(async (data) => {
    const { appKey, appSecret, activated, nfGroup } = data;
    const variables: TVariables = {
      input: {
        appKey,
        appSecret,
        nfce: nfGroup?.includes('nfce'),
        nfe: nfGroup?.includes('nfe'),
        nfse: nfGroup?.includes('nfse'),
        activated,
      },
    };

    try {
      const { data: response } = await createOmieIntegration({ variables });

      if (response?.setOmieStoreIntegration?.ok === true) {
        enqueueSnackbar('Cadastro efetuado com sucesso!', { variant: 'success' });
      } else {
        enqueueSnackbar('Ocorreu um erro, tente mais tarde!', { variant: 'warning' });
      }

      setEnableForm(false);
      onClose();
      await refetch();
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o dados!', { variant: 'error' });
    }
  });

  useEffect(() => {
    const updateFields = () => {
      if (!currentData) return;

      const { appKey, appSecret, nfce, nfe, nfse, activated } = currentData;

      reset({
        appKey,
        appSecret,
        activated,
        nfGroup: [nfce && 'nfce', nfe && 'nfe', nfse && 'nfse'].filter(Boolean),
      });
    };

    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    setEnableForm(!disableForm);
  }, [disableForm]);

  return (
    <FormProvider methods={methods}>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: { xs: '90%', md: 600 },
          },
        }}
      >
        <DialogTitle textAlign="center" fontSize={20}>
          Integração Omie
        </DialogTitle>

        <DialogContent
          sx={{
            minWidth: { xs: 300, md: 600 },
            minHeight: 250,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
            py: 2,
          }}
        >
          <RHFTextField name="appKey" label="APP Key" disabled={!enableForm} />

          <RHFTextField name="appSecret" label="App Secret" disabled={!enableForm} />

          <RHFMultiCheckbox
            row
            spacing={3}
            name="nfGroup"
            options={[
              { value: 'nfce', label: 'Cupom Fiscal' },
              { value: 'nfe', label: 'Nota Fiscal Eletrônica' },
              { value: 'nfse', label: 'Nota fiscal de Serviço' },
            ]}
            disabled={!enableForm}
          />
        </DialogContent>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <RHFSwitch
            name="activated"
            label={watch.activated ? 'Serviço Ativo' : 'Serviço Inativo'}
            disabled={!enableForm}
          />
          <Stack flexDirection="row" spacing={2}>
            <Button variant="outlined" color="inherit" onClick={handleClose}>
              Fechar
            </Button>
            {enableForm && (
              <LoadingButton
                variant="contained"
                color="primary"
                type="submit"
                loading={isSubmitting}
                onClick={onSubmit}
              >
                Salvar
              </LoadingButton>
            )}
            {!enableForm && (
              <Button variant="contained" color="primary" onClick={() => setEnableForm(true)}>
                {currentData ? 'Editar' : 'Criar'}
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
