import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { InputAdornment } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

import { grey } from 'src/theme/palette';

import { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

type CardRecencyFormProps = {
  enableForm: boolean;
};

// ----------------------------------------------------------------------

export function CardRecencyForm({ enableForm }: CardRecencyFormProps) {
  return (
    <Stack spacing={1} sx={{ p: 3, borderRadius: 2, bgcolor: grey[200] }}>
      <Stack gap={3}>
        <Stack gap={2}>
          <FormControlLabel
            label="1"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFTextField
                  name="recency.zoneOneMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
                <RHFTextField
                  name="recency.zoneOneMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="2"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFTextField
                  name="recency.zoneTwoMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
                <RHFTextField
                  name="recency.zoneTwoMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="3"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFTextField
                  name="recency.zoneThreeMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
                <RHFTextField
                  name="recency.zoneThreeMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="4"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFTextField
                  name="recency.zoneFourMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
                <RHFTextField
                  name="recency.zoneFourMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="5"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFTextField
                  name="recency.zoneFiveMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
                <RHFTextField
                  name="recency.zoneFiveMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                  InputProps={{
                    endAdornment: <InputAdornment position='start'>dias</InputAdornment>
                  }}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
