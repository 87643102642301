import * as Yup from 'yup';

export const OmieConfigFormSchema = Yup.object().shape({
  appKey: Yup.string().required('O App Key é obrigatório'),
  appSecret: Yup.string().required('O App Secret é obrigatório'),
  nfGroup: Yup.array().min(1, 'Escolha no mínimo uma das opções acima'),
  activated: Yup.boolean(),
});

export type TOmieConfigFormSchema = Yup.InferType<typeof OmieConfigFormSchema>;

export const omieFormSchemaDefaultValues: TOmieConfigFormSchema = {
  appKey: '',
  appSecret: '',
  nfGroup: [],
  activated: true,
};
