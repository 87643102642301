import { Helmet } from 'react-helmet-async';

import { PermissionUserType } from 'src/graphql/generated';
import PermissionGuard from 'src/auth/guard/permission-guard';

import ConfigRootView from 'src/sections/config/view/config-root-view';

// ----------------------------------------------------------------------

export default function ConfigRootPage() {
  return (
    <>
      <Helmet>
        <title>Configurações</title>
      </Helmet>

      <PermissionGuard permission={PermissionUserType.Admin}>
        <ConfigRootView />
      </PermissionGuard>
    </>
  );
}
