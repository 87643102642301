import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  useTheme,
  Accordion,
  useMediaQuery,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import { useBoolean } from 'src/hooks/use-boolean';

import { useAuthContext } from 'src/auth/hooks';
import { useNotAcceptedTermsQuery, useAcceptTermsMutationMutation } from 'src/graphql/generated';

import Iconify from '../iconify';
import Markdown from '../markdown/markdown';

// ----------------------------------------------------------------------
// type TermPolicyDialogProps = DialogProps & {};
// ----------------------------------------------------------------------

const TERM_TYPE_KEY_MAP = {
  PRIVACY_POLICY: 'Política de Privacidade',
  TERMS_OF_USE: 'Termos de Uso',
};

export default function TermPolicyDialog() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const confirmModal = useBoolean(true);
  const [expanded, setExpanded] = useState<string | false>(false);
  const { logout } = useAuthContext();
  const [acceptMutation] = useAcceptTermsMutationMutation();
  const { data: allTermsHook } = useNotAcceptedTermsQuery();

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleClose = async () => {
    await logout();
    confirmModal.onFalse();
  };

  const handleAccept = async () => {
    const terms_id = allTermsHook?.allTerms?.map((term) => term!.pk.toString());

    try {
      await acceptMutation({ variables: { termsId: terms_id } });
      confirmModal.onFalse();
    } catch (error) {
      enqueueSnackbar('Falha ao realizar ação.', {
        variant: 'error',
      });
    }
  };

  const sortedTerms = allTermsHook?.allTerms?.slice().sort((a, b) => {
    const order = ['TERMS_OF_USE', 'PRIVACY_POLICY'];
    return order.indexOf(a!.termType) - order.indexOf(b!.termType);
  });

  const content = (
    <Box>
      <Typography variant="body1" sx={{ pl: 2, pr: 1, pb: 2 }}>
        Leia e Aceite Nossos Termos e Política de Privacidade para Continuar utilizando o Zeki
      </Typography>

      {sortedTerms?.map((term, i) => (
        <Accordion expanded={expanded === `${i}`} onChange={handleChange(`${i}`)}>
          <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
            <Typography variant="subtitle1">{TERM_TYPE_KEY_MAP[term!.termType]}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            <Markdown>{term?.content}</Markdown>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  return (
    <Dialog fullWidth maxWidth="md" open={confirmModal.value}>
      <DialogTitle sx={{ pl: 4.7, pb: 2, textAlign: isMobile ? 'center' : 'left' }} variant="h4">
        Termos de Uso e Política de Privacidade
      </DialogTitle>

      <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>

      <DialogActions sx={{ gap: 3 }}>
        <Button variant="text" color="inherit" onClick={handleClose}>
          Não Aceito
        </Button>
        <Button variant="contained" color="primary" onClick={handleAccept}>
          Aceito
        </Button>
      </DialogActions>
    </Dialog>
  );
}
