import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { ApolloQueryResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useBoolean } from 'src/hooks/use-boolean';

import {
  ZApiIntegrationType,
  useSaveZapiIntegrationMutation,
  SaveZapiIntegrationMutationVariables,
} from 'src/graphql/generated';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import { ZApiFormSchema, zApiFormSchemaDefaultValues } from '../zApi-form-schema';

// ----------------------------------------------------------------------

type TAttributes = 'zApiId' | 'zApiToken' | 'token';

type TCurrentData = Pick<ZApiIntegrationType, TAttributes>;

type Props = {
  open: boolean;
  onClose: () => void;
  currentData?: TCurrentData | null;
  refetch: () => Promise<ApolloQueryResult<any>>;
};

// ----------------------------------------------------------------------

export function ConfigZapiModal({ open, onClose, currentData, refetch }: Props) {
  const enableForm = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const [saveZapi] = useSaveZapiIntegrationMutation();

  const methods = useForm({
    resolver: yupResolver(ZApiFormSchema),
    defaultValues: zApiFormSchemaDefaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const handleClose = () => {
    if (!currentData) enableForm.onTrue();
    else enableForm.onFalse();

    onClose();
    reset();
  };

  const onSubmit = handleSubmit(async (data) => {
    let label = 'Cadastro efetuado com sucesso!';

    const variables: SaveZapiIntegrationMutationVariables = {
      input: {
        zApiId: data.id,
        zApiToken: data.apiToken,
        token: data.securityToken,
      },
    };

    if (currentData) label = 'Dados atualizados com sucesso!';

    try {
      await saveZapi({ variables });

      enqueueSnackbar(label, { variant: 'success' });

      await refetch();

      enableForm.onFalse();
      onClose();
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o dados!', { variant: 'error' });
    }
  });

  useEffect(() => {
    const updateFields = () => {
      if (!currentData) return;

      reset({
        id: currentData?.zApiId,
        apiToken: currentData?.zApiToken,
        securityToken: currentData?.token!,
      });
    };

    updateFields();
  }, [currentData, reset]);

  useEffect(() => {
    if (!currentData) {
      enableForm.onTrue();

      return;
    }

    enableForm.onFalse();
    // eslint-disable-next-line
  }, [currentData]);

  return (
    <FormProvider methods={methods}>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: { xs: '90%', md: 600 },
          },
        }}
      >
        <DialogTitle textAlign="center" fontSize={20}>
          Integração Z-Api
        </DialogTitle>

        <DialogContent
          sx={{
            minWidth: { xs: 300, md: 600 },
            minHeight: 280,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
            marginBottom: 2,
            py: 2,
          }}
        >
          <RHFTextField name="id" label="ID da instância" disabled={!enableForm.value} />

          <RHFTextField name="apiToken" label="Token da instância" disabled={!enableForm.value} />

          <RHFTextField
            name="securityToken"
            label="Token de Segurança da conta"
            disabled={!enableForm.value}
          />
        </DialogContent>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Fechar
          </Button>
          {enableForm.value && (
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Salvar
            </LoadingButton>
          )}
          {!enableForm.value && (
            <Button variant="contained" color="primary" onClick={enableForm.onTrue}>
              Editar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
