import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, useLayoutEffect } from 'react';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemText from '@mui/material/ListItemText';

import {
  useGetAllRfmQuery,
  UpdateRfmConfigMutationVariables,
  useUpdateRfmConfigMutation as useUpdateRfmConfig,
} from 'src/graphql/generated';

import FormProvider from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';

import { CardValueForm } from './components/card-value-form';
import { CardRecencyForm } from './components/card-recency-form';
import { CardFrequencyForm } from './components/card-frequency-form';
import { RfmFormSchema, rfmFormSchemaDefaultValues } from './rfm-form-schema';

// ----------------------------------------------------------------------

export default function ConfigCustomerTab() {
  const [enableForm, setEnableForm] = useState<boolean>(false);
  const { data: rfmData, refetch: refetchAll } = useGetAllRfmQuery();

  const { enqueueSnackbar } = useSnackbar();
  const [updateRfm] = useUpdateRfmConfig();

  const methods = useForm({
    resolver: yupResolver(RfmFormSchema),
    defaultValues: rfmFormSchemaDefaultValues,
  });

  const {
    handleSubmit,
    reset,
    watch,
    trigger,
    formState: { isSubmitting },
  } = methods;

  const watchedValues = watch();

  const updateFields = () => {
    if (!rfmData) return;

    reset({
      recency: {
        zoneOneMin: rfmData?.recency?.zoneOneMin,
        zoneOneMax: rfmData?.recency?.zoneOneMax,
        zoneTwoMin: rfmData?.recency?.zoneTwoMin,
        zoneTwoMax: rfmData?.recency?.zoneTwoMax,
        zoneThreeMin: rfmData?.recency?.zoneThreeMin,
        zoneThreeMax: rfmData?.recency?.zoneThreeMax,
        zoneFourMin: rfmData?.recency?.zoneFourMin,
        zoneFourMax: rfmData?.recency?.zoneFourMax,
        zoneFiveMin: rfmData?.recency?.zoneFiveMin,
        zoneFiveMax: rfmData?.recency?.zoneFiveMax,
      },
      frequency: {
        zoneOneMin: rfmData?.frequency?.zoneOneMin,
        zoneOneMax: rfmData?.frequency?.zoneOneMax,
        zoneTwoMin: rfmData?.frequency?.zoneTwoMin,
        zoneTwoMax: rfmData?.frequency?.zoneTwoMax,
        zoneThreeMin: rfmData?.frequency?.zoneThreeMin,
        zoneThreeMax: rfmData?.frequency?.zoneThreeMax,
        zoneFourMin: rfmData?.frequency?.zoneFourMin,
        zoneFourMax: rfmData?.frequency?.zoneFourMax,
        zoneFiveMin: rfmData?.frequency?.zoneFiveMin,
        zoneFiveMax: rfmData?.frequency?.zoneFiveMax,
      },
      value: {
        zoneOneMin: !rfmData?.value?.zoneOneMin ? 0 : rfmData.value.zoneOneMin * 100,
        zoneOneMax: !rfmData?.value?.zoneOneMax ? 0 : rfmData.value.zoneOneMax * 100,
        zoneTwoMin: !rfmData?.value?.zoneTwoMin ? 0 : rfmData.value.zoneTwoMin * 100,
        zoneTwoMax: !rfmData?.value?.zoneTwoMax ? 0 : rfmData.value.zoneTwoMax * 100,
        zoneThreeMin: !rfmData?.value?.zoneThreeMin ? 0 : rfmData.value.zoneThreeMin * 100,
        zoneThreeMax: !rfmData?.value?.zoneThreeMax ? 0 : rfmData.value.zoneThreeMax * 100,
        zoneFourMin: !rfmData?.value?.zoneFourMin ? 0 : rfmData.value.zoneFourMin * 100,
        zoneFourMax: !rfmData?.value?.zoneFourMax ? 0 : rfmData.value.zoneFourMax * 100,
        zoneFiveMin: !rfmData?.value?.zoneFiveMin ? 0 : rfmData.value.zoneFiveMin * 100,
        zoneFiveMax: !rfmData?.value?.zoneFiveMax ? 0 : rfmData.value.zoneFiveMax * 100,
      },
    });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      const variables: UpdateRfmConfigMutationVariables = {
        recency: data.recency,
        frequency: data.frequency,
        value: {
          zoneOneMin: !data?.value?.zoneOneMin ? 0 : data.value.zoneOneMin / 100,
          zoneOneMax: !data?.value?.zoneOneMax ? 0 : data.value.zoneOneMax / 100,
          zoneTwoMin: !data?.value?.zoneTwoMin ? 0 : data.value.zoneTwoMin / 100,
          zoneTwoMax: !data?.value?.zoneTwoMax ? 0 : data.value.zoneTwoMax / 100,
          zoneThreeMin: !data?.value?.zoneThreeMin ? 0 : data.value.zoneThreeMin / 100,
          zoneThreeMax: !data?.value?.zoneThreeMax ? 0 : data.value.zoneThreeMax / 100,
          zoneFourMin: !data?.value?.zoneFourMin ? 0 : data.value.zoneFourMin / 100,
          zoneFourMax: !data?.value?.zoneFourMax ? 0 : data.value.zoneFourMax / 100,
          zoneFiveMin: !data?.value?.zoneFiveMin ? 0 : data.value.zoneFiveMin / 100,
          zoneFiveMax: !data?.value?.zoneFiveMax ? 0 : data.value.zoneFiveMax / 100,
        },
      };

      await updateRfm({ variables });

      enqueueSnackbar('Dados atualizados!', { variant: 'success' });

      await refetchAll();

      setEnableForm(false);
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }

      enqueueSnackbar('Não foi possível atualizar os dados!', { variant: 'error' });
    }
  });

  useEffect(() => {
    updateFields();
    // eslint-disable-next-line
  }, [rfmData]);

  useLayoutEffect(() => {
    const onRefetch = async () => {
      await refetchAll();
    };

    onRefetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!rfmData) return;

    trigger();
  }, [trigger, watchedValues, rfmData]);

  const renderRecencyCardForm = (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <ListItemText
          primary="Recência"
          secondary={
            <>
              Recência faz referência ao número de dias que um cliente demora para voltar a seu
              estabelecimento.
              <br />
              <br />
              <b>OBS:</b> A Faixa 1 é o melhor comportamento esperado e a Faixa 5 o pior
              comportamento.
            </>
          }
          primaryTypographyProps={{ typography: 'h6', mb: 0.5 }}
          secondaryTypographyProps={{ component: 'span' }}
        />
      </Grid>

      <Grid xs={12} md={8}>
        <CardRecencyForm enableForm={enableForm} />
      </Grid>
    </Grid>
  );

  const renderFrequencyCardForm = (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <ListItemText
          primary="Frequência"
          secondary={
            <>
              Frequência faz referência ao número de vezes que um cliente frequenta seu
              estabelecimento.
              <br />
              <br />
              <b>OBS:</b> A Faixa 5 é o melhor comportamento esperado e a Faixa 1 o pior
              comportamento.
            </>
          }
          primaryTypographyProps={{ typography: 'h6', mb: 0.5 }}
          secondaryTypographyProps={{ component: 'span' }}
        />
      </Grid>

      <Grid xs={12} md={8}>
        <CardFrequencyForm enableForm={enableForm} />
      </Grid>
    </Grid>
  );

  const renderValueCardForm = (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <ListItemText
          primary="Valor monetário"
          secondary={
            <>
              Valor faz referencia à média de gastos médio que um cliente realiza no seu
              estabelecimento.
              <br />
              <br />
              <b>OBS:</b> A Faixa 5 é o melhor comportamento esperado e a Faixa 1 o pior
              comportamento.
            </>
          }
          primaryTypographyProps={{ typography: 'h6', mb: 0.5 }}
          secondaryTypographyProps={{ component: 'span' }}
        />
      </Grid>

      <Grid xs={12} md={8}>
        <CardValueForm enableForm={enableForm} />
      </Grid>
    </Grid>
  );

  return (
    <FormProvider methods={methods}>
      <Grid container spacing={3}>
        <Grid xs={12} gap={3} display="flex" flexDirection="column">
          <ListItemText
            primary="Personalize a classificação dos seus clientes!"
            secondary={
              <>
                Configure a Matriz RFM de acordo com a sua estratégia de negócio, permitindo
                avaliações mais precisas e personalizadas que estejam alinhadas com suas metas.
                <br />
                <br />
                Classifique os clientes e obtenha análises e comunicações personalizadas.
                <br />
                <br />
                <b>Recência:</b> A Faixa 1 é o melhor comportamento esperado e a Faixa 5 o pior
                comportamento.
                <br />
                <br />
                <b>Frequência e Valor Monetário:</b> A Faixa 5 é o melhor comportamento esperado e a
                Faixa 1 o pior comportamento.
              </>
            }
            // secondary="Configure a Matriz RFM de acordo com a sua estratégia de negócio, permitindo avaliações mais precisas e personalizadas que estejam alinhadas com suas metas.  Classifique os clientes e obtenha análises e comunicações personalizadas.  A Faixa 1 é o melhor comportamento esperado e a Faixa 5 o pior comportamento. "
            primaryTypographyProps={{ typography: 'h5', mb: 0.5, component: 'h2' }}
            secondaryTypographyProps={{ component: 'span' }}
            sx={{
              pr: 3,
              pl: 3,
            }}
          />
          <Stack component={Card} spacing={3} sx={{ p: 3 }}>
            {renderRecencyCardForm}
            {renderFrequencyCardForm}
            {renderValueCardForm}
            {enableForm && (
              <Stack flexDirection="row" justifyContent="flex-end" alignItems="flex-end" gap={2}>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => {
                    updateFields();
                    setEnableForm(false);
                  }}
                >
                  Cancelar
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  color="primary"
                  onClick={onSubmit}
                >
                  Salvar
                </LoadingButton>
              </Stack>
            )}
            {!enableForm && (
              <Stack display="flex" flexDirection="row" justifyContent="flex-end">
                <Button variant="contained" color="primary" onClick={() => setEnableForm(true)}>
                  Editar
                </Button>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
