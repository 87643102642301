import * as Yup from 'yup';

export const TrayConfigFormSchema = Yup.object().shape({
  code: Yup.string().required('Código da Loja é obrigatório'),
  apiKey: Yup.string().required('API Key é obrigatório'),
});

export type TTrayConfigFormSchema = Yup.InferType<typeof TrayConfigFormSchema>;

export const trayFormSchemaDefaultValues: TTrayConfigFormSchema = {
  code: '',
  apiKey: '',
};
