import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import NewZeki from 'src/assets/illustrations/zeki-new';

import Logo from 'src/components/logo';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  image?: string;
  children: React.ReactNode;
};

export default function AuthClassicLayout({ children, image, title }: Props) {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();

  const renderContent = (
    <Stack
      sx={{
        width: 1,
        mx: 'auto',
        // maxWidth: 480,
        flex: 1,
        px: { xs: 2, md: 8 },
        alignItems: 'center',
      }}
    >
      <Logo
        sx={{
          mt: { xs: 2, md: '140px' },
          mb: { xs: 0, md: 4 },
        }}
      />

      <Card
        sx={{
          py: { xs: 5, md: 0 },
          px: { xs: 3, md: 0 },
          mt: { xs: 4, md: 0 },
          boxShadow: { md: 'none' },
          overflow: { md: 'unset' },
          bgcolor: { md: 'background.default' },
          width: '100%',
          minWidth: 325,
          maxWidth: 600,
        }}
      >
        {children}
      </Card>
    </Stack>
  );

  const renderSection = (
    <Stack
      flexGrow={1}
      gap={{
        md: 5,
        xl: 6,
      }}
      sx={{
        position: 'relative',
        display: 'flex',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: 'url(/assets/background/gradient_blue.png)',
        backgroundSize: 'cover',
        // ...bgGradient({
        //   startColor: theme.palette.secondary.main,
        //   endColor: theme.palette.primary.main,
        //   direction: '311.67deg',
        // }),
        px: {
          md: '80px',
          xl: '120px',
        },
        py: '120px',
      }}
    >
      <Stack
        gap={{
          md: 3,
          xl: 4,
        }}
      >
        <Typography
          fontWeight={900}
          variant="h2"
          color={theme.palette.primary.contrastText}
          sx={{
            fontFamily: 'Segoe Black',
            fontWeight: 900,
            maxWidth: 480,
            lineHeight: {
              md: '50px',
              xl: '64px',
            },
            textAlign: 'left',
            fontSize: {
              xl: '4rem',
            },
          }}
        >
          Transforme <br />
          seu pós-venda <br />
          com o Zeki
        </Typography>

        <Typography
          color={theme.palette.primary.contrastText}
          fontSize={{
            md: 20,
            xl: 24,
          }}
        >
          Um sistema intuitivo que gera insights, <br />
          aumenta as vendas e fideliza os clientes.
        </Typography>
      </Stack>

      <Stack
        sx={{
          width: {
            md: '220px',
            xl: '320px',
          },
          height: {
            md: '230px',
            xl: '330px',
          },
        }}
      >
        <NewZeki />
      </Stack>
    </Stack>
  );

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
        position: 'relative',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          position: 'absolute',
          backgroundSize: 'cover',
          opacity: { xs: 0.24, md: 0 },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: 'url(/assets/background/overlay_4.jpg)',
        },
      }}
    >
      {mdUp && renderSection}

      {renderContent}
    </Stack>
  );
}
