import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import Image from 'src/components/image';
import Iconify from 'src/components/iconify';

// ----------------------------------------------------------------------

type CardIntegrationProps = {
  logoURL: string;
  isConnected: boolean;
  hasData: boolean;
  heading: string;
  subheading: string;
  onAction: () => void;
};

// ----------------------------------------------------------------------

export function CardIntegration({
  logoURL,
  heading,
  subheading,
  isConnected,
  hasData,
  onAction,
}: CardIntegrationProps) {
  return (
    <Stack component={Card}>
      <Stack sx={{ p: 3, pb: 2 }} flex="1">
        <Image
          src={logoURL}
          height="48px"
          width="48px"
          sx={{
            '& span.component-image-wrapper img': {
              objectFit: 'contain',
            },
          }}
        />

        <ListItemText
          sx={{ mb: 1 }}
          primary={heading}
          secondary={subheading}
          primaryTypographyProps={{
            typography: 'subtitle1',
          }}
          secondaryTypographyProps={{
            mt: 1,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />

        {isConnected ? (
          <Stack
            spacing={0.5}
            direction="row"
            alignItems="center"
            sx={{ color: 'success.main', typography: 'caption' }}
          >
            <Iconify width={16} icon="eva:checkmark-fill" />
            Conectado
          </Stack>
        ) : (
          <Stack
            spacing={0.5}
            direction="row"
            alignItems="center"
            sx={{ color: 'error.main', typography: 'caption' }}
          >
            <Iconify width={16} icon="eva:close-fill" />
            Não Conectado
          </Stack>
        )}
      </Stack>

      <Divider sx={{ borderStyle: 'dashed' }} />
      <Stack alignItems="flex-end" sx={{ p: 3 }}>
        {hasData ? (
          <Button variant="contained" color="primary" onClick={onAction}>
            Editar
          </Button>
        ) : (
          <Button variant="contained" color="secondary" onClick={onAction}>
            Integrar
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
