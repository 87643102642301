import * as Yup from 'yup';

// ----------------------------------------------------------------------

export const ResetPasswordFormSchema = Yup.object().shape({
  currentPassword: Yup.string().
    required('Senha Atual obrigatória').
    min(8, '8 digítos no mínimo'),
  newPassword: Yup.string().
    min(8, '8 digítos no mínimo').
    required('Nova Senha obrigatória').
    test(
      'no-match',
      'Nova senha não pode ser igual a antiga',
      (value, { parent }) => value !== parent.currentPassword
    ),
  confirmPassword: Yup.string()
    .required('Confirmar Senha obrigatório')
    .oneOf([Yup.ref('newPassword'), ''], 'As senhas devem coincidir'),
});

export type TResetPasswordFormSchema = Yup.InferType<typeof ResetPasswordFormSchema>;

export const resetPasswordSchemaDefaultValues: TResetPasswordFormSchema = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};
