import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ColorSchema =
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'extra'
  | 'greyPalette';

declare module '@mui/material/styles/createPalette' {
  interface TypeBackground {
    neutral: string;
  }
  interface SimplePaletteColorOptions {
    lighter: string;
    darker: string;
  }
  interface PaletteColor {
    lighter: string;
    darker: string;
  }
  interface Palette {
    extra: PaletteColor;
    greyPalette: PaletteColor;
  }
}

// SETUP COLORS

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const greyPalette = {
  lighter: '#F4F6F8',
  light: '#DFE3E8',
  main: '#919EAB',
  dark: '#454F5B',
  darker: '#080A0D',
  contrastText: '#FFFFFF',
};

export const primary = {
  lighter: '#93B5E6',
  light: '#5078B5',
  main: '#2456A3',
  dark: '#1F4685',
  darker: '#1D3C6F',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lighter: '#D2F5F2',
  light: '#6FD9D4',
  main: '#45C1C0',
  dark: '#1D8184',
  darker: '#053e40 ',
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#A2F4FF',
  light: '#00B8D9',
  main: '#0393B7',
  dark: '#0A7594',
  darker: '#144E65',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#86EFAD',
  light: '#4ADE80',
  main: '#16A34A',
  dark: '#15803C',
  darker: '#14532B',
  contrastText: '#ffffff',
};

export const warning = {
  lighter: '#FFF6C5',
  light: '#FFCD1B',
  main: '#FFAB00',
  dark: '#E28200',
  darker: '#984508',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FFE9D5',
  light: '#FFAC82',
  main: '#E6492E',
  dark: '#C60C08',
  darker: '#7E1011',
  contrastText: '#FFFFFF',
};

export const extra = {
  lighter: '#B7B1FF',
  light: '#9385FF',
  main: '#7B61FF',
  dark: '#513FAB',
  darker: '#402E97',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(grey[500], 0.08),
  selected: alpha(grey[500], 0.16),
  disabled: alpha(grey[500], 0.8),
  disabledBackground: alpha(grey[500], 0.24),
  focus: alpha(grey[500], 0.24),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  extra,
  grey,
  greyPalette,
  common,
  divider: alpha(grey[500], 0.2),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode: 'light' | 'dark') {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: grey[800],
      secondary: grey[600],
      disabled: grey[500],
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
