import * as Yup from 'yup';

import { useLocales as getLocales } from 'src/locales';
import { GiftbackConfigValueType } from 'src/graphql/generated';

const REGEX_EMAIL = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;

export const ConfigFormSchema = Yup.object().shape({
  name: Yup.string().required('Nome da Loja é obrigatório'),
  email: Yup.string()
    .required('Email é obrigatório')
    .matches(REGEX_EMAIL, 'Email precisa de endereço válido de email'),
  phone: Yup.string().min(10, 'Mínimo de 10 dígitos').required('Telefone é obrigatório'),
  taxDocument: Yup.string().required('Documento é obrigatório'),
  site: Yup.string().nullable(),
  currencyType: Yup.object()
    .shape({
      value: Yup.string().required('Obrigatório'),
      label: Yup.string().required('Obrigatório'),
    })
    .nullable(),
});

export const CURRENCY_TYPES_VALUE = [
  {
    label: 'Real',
    value: 'REAL',
  },
  {
    label: 'Dolar',
    value: 'DOLAR',
  },
];

export type TConfigFormSchema = Yup.InferType<typeof ConfigFormSchema>;

export const configFormSchemaDefaultValues: TConfigFormSchema = {
  name: '',
  email: '',
  phone: '',
  taxDocument: '',
  site: '',
  currencyType: CURRENCY_TYPES_VALUE[0],
};

export const ConfigFormGiftBackSchema = Yup.object().shape({
  valueType: Yup.object()
    .shape({
      value: Yup.string().required('Obrigatório'),
      label: Yup.string().required('Obrigatório'),
    })
    .nullable(),
  minimumValueType: Yup.object()
    .shape({
      value: Yup.string().required('Obrigatório'),
      label: Yup.string().required('Obrigatório'),
    })
    .nullable(),
  value: Yup.string().required('Insira o valor'),
  minPurchaseValueForCreate: Yup.string().required('Insira o valor mínimo para gerar um GiftBack'),
  maximumDiscountPercentage: Yup.string().when('minimumValueType', {
    // @ts-ignore
    is: (minimumValueTypeValue) =>
      minimumValueTypeValue.value === GiftbackConfigValueType.Percentage,
    then: (schema) => schema.required('Insira o percentual máximo de desconto'),
    otherwise: (schema) => schema.optional(),
  }),
  dueDate: Yup.string().required('Insira quantidade de dias'),
  minPurchaseValueForRedeem: Yup.string().when('minimumValueType', {
    // @ts-ignore
    is: (minimumValueTypeValue) =>
      minimumValueTypeValue.value === GiftbackConfigValueType.FixedValue,
    then: (schema) => schema.required('Insira o valor mínimo para o resgate do GiftBack'),
    otherwise: (schema) => schema.optional(),
  }),
});

export type TConfigFormGiftBackSchema = Yup.InferType<typeof ConfigFormGiftBackSchema>;

export const TYPES_VALUE = [
  {
    label: getLocales().currentLang.currencyShort,
    value: 'FIXED_VALUE',
  },
  {
    label: '%',
    value: 'PERCENTAGE',
  },
];
export const ConfigFormGiftBackSchemaDefaultValues: TConfigFormGiftBackSchema = {
  valueType: TYPES_VALUE[1],
  minimumValueType: TYPES_VALUE[1],
  value: '',
  minPurchaseValueForRedeem: '',
  dueDate: '',
  minPurchaseValueForCreate: '',
  maximumDiscountPercentage: '',
};
