import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useState, useEffect } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Divider from '@mui/material/Divider';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import {
  TrayStoreIntegrationType,
  useCreateTrayStoreIntegrationMutation,
  CreateTrayStoreIntegrationMutationVariables as TVariables,
} from 'src/graphql/generated';

import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';

import { TrayConfigFormSchema, trayFormSchemaDefaultValues } from '../tray-form-schema';

// ----------------------------------------------------------------------

type TAttributes = 'code' | 'apiKey';

type TCurrentData = Pick<TrayStoreIntegrationType, TAttributes>;

type Props = {
  open: boolean;
  onClose: () => void;
  disableForm?: boolean;
  currentData?: TCurrentData | null;
  refetch: () => Promise<ApolloQueryResult<any>>;
};

// ----------------------------------------------------------------------

export function ConfigTrayModal({
  open,
  onClose,
  currentData,
  disableForm = false,
  refetch,
}: Props) {
  const [enableForm, setEnableForm] = useState<boolean>(!disableForm);
  const [createTrayStoreIntegration] = useCreateTrayStoreIntegrationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    resolver: yupResolver(TrayConfigFormSchema),
    defaultValues: trayFormSchemaDefaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const handleClose = () => {
    onClose();
    reset();
    setEnableForm(!disableForm);
  };

  const onSubmit = handleSubmit(async (data) => {
    const { code, apiKey } = data;

    const variables: TVariables = {
      trayInput: {
        code,
        apiKey,
      },
    };

    try {
      const { data: response } = await createTrayStoreIntegration({ variables });

      if (response?.createTrayStoreIntegration?.success === true) {
        enqueueSnackbar('Cadastro efetuado com sucesso!', { variant: 'success' });
      } else {
        enqueueSnackbar(`${response?.createTrayStoreIntegration?.error}`, { variant: 'warning' });
      }

      setEnableForm(false);
      onClose();
      await refetch();
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });

        return;
      }
      enqueueSnackbar('Não foi possível atualizar o dados!', { variant: 'error' });
    }
  });

  useEffect(() => {
    const updateFields = () => {
      if (!currentData) return;

      const { code, apiKey } = currentData;

      reset({
        code,
        apiKey,
      });
    };

    updateFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentData]);

  useEffect(() => {
    setEnableForm(!disableForm);
  }, [disableForm]);

  return (
    <FormProvider methods={methods}>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            minWidth: { xs: '90%', md: 600 },
          },
        }}
      >
        <DialogTitle textAlign="center" fontSize={20}>
          Integração Tray
        </DialogTitle>

        <DialogContent
          sx={{
            minWidth: { xs: 300, md: 600 },
            minHeight: 180,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            gap: 2,
            marginBottom: 2,
            py: 2,
          }}
        >
          <RHFTextField name="apiKey" label="URL" disabled={!enableForm} />

          <RHFTextField name="code" label="Código" disabled={!enableForm} />
        </DialogContent>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={handleClose}>
            Fechar
          </Button>
          {enableForm && (
            <LoadingButton
              variant="contained"
              color="primary"
              type="submit"
              loading={isSubmitting}
              onClick={onSubmit}
            >
              Salvar
            </LoadingButton>
          )}
          {!enableForm && (
            <Button variant="contained" color="primary" onClick={() => setEnableForm(true)}>
              {currentData ? 'Editar' : 'Criar'}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
}
