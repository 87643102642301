import * as Yup from 'yup';

export const ZApiFormSchema = Yup.object().shape({
  id: Yup.string().required('Código da Loja é obrigatório'),
  apiToken: Yup.string().required('Token da Instância é obrigatório'),
  securityToken: Yup.string().required('Token de Segurança da conta é obrigatório')
});

export type TZApiConfigFormSchema = Yup.InferType<typeof ZApiFormSchema>;

export const zApiFormSchemaDefaultValues: TZApiConfigFormSchema = {
  id: '',
  apiToken: '',
  securityToken: ''
};
