import * as Yup from 'yup';

// ----------------------------------------------------------------------

const MIN_REQUIRE_MESSAGE = 'Mínimo é obrigatório';
const MAX_REQUIRE_MESSAGE = 'Máximo é obrigatório';
const MAX_GREATER_MIN_MESSAGE = 'O Máximo precisa ser maior que o mínimo';
const MIN_GREATER_PREDECESSOR_MESSAGE = 'O Mínimo precisa ser maior que o antecessor';
const NUMERIC_ERROR_MESSAGE = 'Deve ser um valor numérico';

// ----------------------------------------------------------------------

export const RfmFormSchema = Yup.object().shape({
  recency: Yup.object().shape({
    zoneOneMin: Yup.number().typeError(NUMERIC_ERROR_MESSAGE).required(MIN_REQUIRE_MESSAGE),
    zoneOneMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MAX_REQUIRE_MESSAGE)
      .test('is-recency-zoneOneMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneOneMin;
      }),
    zoneTwoMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-recency-zoneTwoMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneOneMax;
        }
      ),
    zoneTwoMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-recency-zoneTwoMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneTwoMin;
      }),
    zoneThreeMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-recency-zoneThreeMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneTwoMax;
        }
      ),
    zoneThreeMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-recency-zoneThreeMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneThreeMin;
      }),
    zoneFourMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-recency-zoneFourMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneThreeMax;
        }
      ),
    zoneFourMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-recency-zoneFourMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneFourMin;
      }),
    zoneFiveMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-recency-zoneFiveMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneFourMax;
        }
      ),
    zoneFiveMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-recency-zoneFiveMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneFiveMin;
      }),
  }),
  value: Yup.object().shape({
    zoneOneMin: Yup.number().typeError(NUMERIC_ERROR_MESSAGE).required(MIN_REQUIRE_MESSAGE),
    zoneOneMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MAX_REQUIRE_MESSAGE)
      .test('is-value-zoneOneMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneOneMin;
      }),
    zoneTwoMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-value-zoneTwoMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneOneMax;
        }
      ),
    zoneTwoMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-value-zoneTwoMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneTwoMin;
      }),
    zoneThreeMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-value-zoneThreeMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneTwoMax;
        }
      ),
    zoneThreeMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-value-zoneThreeMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneThreeMin;
      }),
    zoneFourMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-value-zoneFourMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneThreeMax;
        }
      ),
    zoneFourMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-value-zoneFourMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneFourMin;
      }),
    zoneFiveMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-value-zoneFiveMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneFourMax;
        }
      ),
    zoneFiveMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-value-zoneFiveMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneFiveMin;
      }),
  }),
  frequency: Yup.object().shape({
    zoneOneMin: Yup.number().typeError(NUMERIC_ERROR_MESSAGE).required(MIN_REQUIRE_MESSAGE),
    zoneOneMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MAX_REQUIRE_MESSAGE)
      .test('is-frequency-zoneOneMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneOneMin;
      }),
    zoneTwoMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-frequency-zoneTwoMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneOneMax;
        }
      ),
    zoneTwoMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-frequency-zoneTwoMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneTwoMin;
      }),
    zoneThreeMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-frequency-zoneThreeMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneTwoMax;
        }
      ),
    zoneThreeMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-frequency-zoneThreeMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneThreeMin;
      }),
    zoneFourMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-frequency-zoneFourMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneThreeMax;
        }
      ),
    zoneFourMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-frequency-zoneFourMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneFourMin;
      }),
    zoneFiveMin: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test(
        'is-frequency-zoneFiveMin-valid',
        MIN_GREATER_PREDECESSOR_MESSAGE,
        function validateZone(value) {
          return value > this.parent.zoneFourMax;
        }
      ),
    zoneFiveMax: Yup.number()
      .typeError(NUMERIC_ERROR_MESSAGE)
      .required(MIN_REQUIRE_MESSAGE)
      .test('is-frequency-zoneFiveMax-valid', MAX_GREATER_MIN_MESSAGE, function validateZone(value) {
        return value > this.parent.zoneFiveMin;
      }),
  }),
});

export type TRfmFormSchema = Yup.InferType<typeof RfmFormSchema>;

export const rfmFormSchemaDefaultValues: TRfmFormSchema = {
  recency: {
    zoneOneMin: 0,
    zoneOneMax: 0,
    zoneTwoMin: 0,
    zoneTwoMax: 0,
    zoneThreeMin: 0,
    zoneThreeMax: 0,
    zoneFourMin: 0,
    zoneFourMax: 0,
    zoneFiveMin: 0,
    zoneFiveMax: 0,
  },
  value: {
    zoneOneMin: 0,
    zoneOneMax: 0,
    zoneTwoMin: 0,
    zoneTwoMax: 0,
    zoneThreeMin: 0,
    zoneThreeMax: 0,
    zoneFourMin: 0,
    zoneFourMax: 0,
    zoneFiveMin: 0,
    zoneFiveMax: 0,
  },
  frequency: {
    zoneOneMin: 0,
    zoneOneMax: 0,
    zoneTwoMin: 0,
    zoneTwoMax: 0,
    zoneThreeMin: 0,
    zoneThreeMax: 0,
    zoneFourMin: 0,
    zoneFourMax: 0,
    zoneFiveMin: 0,
    zoneFiveMax: 0,
  },
};
