import { useMemo, useState, useEffect } from 'react';

import { paths } from 'src/routes/paths';

import { useHasPermission } from 'src/hooks/use-has-permission';

import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  customer: icon('ic_customer'),
  file: icon('ic_file'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  product: icon('ic_hanger'),
  totem: icon('ic_totem'),
  analytics: icon('ic_analytics'),
  campaign: icon('ic_chat'),
  settings: icon('ic_settings'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { hasAdminPermission, isLoading } = useHasPermission();
  const [permissionsReady, setPermissionsReady] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      setPermissionsReady(true);
    }
  }, [isLoading]);

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: 'Zeki',
        items: [
          {
            title: 'Home',
            path: paths.dashboard.root,
            icon: ICONS.tour,
          },
          {
            title: 'Dashboards',
            path: paths.dashboard.analytics.customer,
            icon: ICONS.analytics,
            children: [
              { title: 'Clientes', path: paths.dashboard.analytics.customer },
              {
                title: 'Giftbacks',
                path: paths.dashboard.analytics.giftback,
              },
              { title: 'Vendas', path: paths.dashboard.analytics.sales },
            ],
          },
          ...(hasAdminPermission
            ? [
                {
                  title: 'Usuários',
                  path: paths.dashboard.user.root,
                  icon: ICONS.user,
                },
              ]
            : []),
          {
            title: 'Clientes',
            path: paths.dashboard.customer.root,
            icon: ICONS.customer,
          },
          {
            title: 'Giftbacks',
            path: paths.dashboard.giftback.root,
            icon: ICONS.label,
          },
          {
            title: 'PDV',
            path: paths.dashboard.pdv.root,
            icon: ICONS.file,
          },
          {
            title: 'Vendas',
            path: paths.dashboard.purchase.root,
            icon: ICONS.order,
          },
          {
            title: 'Produtos',
            path: paths.dashboard.product.root,
            icon: ICONS.product,
          },
          {
            title: 'Totem',
            path: paths.totem,
            icon: ICONS.totem,
          },
          {
            title: 'Campanhas',
            path: paths.dashboard.campaign.root,
            icon: ICONS.campaign,
          },
          ...(hasAdminPermission
            ? [
                {
                  title: 'Configurações',
                  path: paths.dashboard.config.root,
                  icon: ICONS.settings,
                },
              ]
            : []),
        ],
      },
    ],
    [hasAdminPermission]
  );

  return permissionsReady ? data : [];
}
