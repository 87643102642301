import { memo } from 'react';

import Box, { BoxProps } from '@mui/material/Box';

// ----------------------------------------------------------------------

function PasswordIcon({ ...other }: BoxProps) {

  return (
    <Box
      component="svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 96 96"
      xmlns="http://www.w3.org/2000/svg"
      {...other}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="97"
        height="96"
        fill="none"
        viewBox="0 0 97 96"
      >
        <path
          fill="url(#paint0_linear_258_7616)"
          d="M36.824 29.39v7.76a4.803 4.803 0 01-9.606 0v-7.76c0-11.676 9.405-21.198 21.037-21.387L48.608 8c11.678 0 21.199 9.405 21.388 21.037l.003.354v7.76a4.803 4.803 0 11-9.606 0v-7.76c0-6.433-5.181-11.68-11.59-11.783l-.194-.002c-6.499 0-11.785 5.286-11.785 11.784z"
        />
        <g style={{ mixBlendMode: "overlay" }} filter="url(#filter0_i_258_7616)">
          <path
            fill="#fff"
            fillOpacity="0.08"
            d="M36.824 29.39v12.563h-9.606V29.39c0-11.676 9.405-21.198 21.037-21.387L48.608 8c11.678 0 21.199 9.405 21.388 21.037l.003.354v12.563h-9.606V29.39c0-6.433-5.181-11.678-11.59-11.782l-.194-.002c-6.499 0-11.785 5.286-11.785 11.784z"
          />
        </g>
        <path
          fill="url(#paint1_linear_258_7616)"
          fillRule="evenodd"
          d="M79.717 68.127c0 10.979-8.9 19.878-19.877 19.878H37.377c-10.978 0-19.877-8.9-19.877-19.877V47.876a8.277 8.277 0 018.277-8.277H71.44a8.277 8.277 0 018.277 8.277v20.25zM45.01 66.862a3.598 3.598 0 003.598 3.598H50a2.206 2.206 0 002.206-2.206c0-.909.57-1.705 1.358-2.157a9.935 9.935 0 002.254-1.768 9.865 9.865 0 002.715-7.369c-.268-5.176-4.538-9.311-9.724-9.417h-.064l-.134.002-.002-.001h-.002c-4.643 0-9.152 3.578-9.848 8.558-.071.544.367 1.002.916 1.002h6.204a.01.01 0 00.009-.01c.026-.187.07-.367.132-.54l.02-.056.031-.078.028-.064a2.715 2.715 0 01.935-1.129l.07-.047.06-.038.044-.026.048-.028a2.808 2.808 0 01.215-.108l.087-.037a2.64 2.64 0 01.136-.052l.06-.02a2.748 2.748 0 01.351-.088l.057-.01.058-.008.067-.008c.042-.005.085-.009.128-.012l.061-.003.063-.003h.068l.002.002.056-.002c1.406.03 2.61 1.19 2.682 2.596a2.735 2.735 0 01-.748 2.039l-.049.049-.06.057a2.722 2.722 0 01-1.806.746l-.076.001h-.676a2.922 2.922 0 00-2.922 2.923v3.712zm3.598 6.113a3.545 3.545 0 00-.001 7.088h.064a3.544 3.544 0 00-.063-7.088z"
          clipRule="evenodd"
        />
        <g style={{ mixBlendMode: "overlay" }} filter="url(#filter1_i_258_7616)">
          <path
            fill="#fff"
            fillOpacity="0.08"
            fillRule="evenodd"
            d="M79.717 68.127c0 10.979-8.9 19.878-19.877 19.878H37.377c-10.978 0-19.877-8.9-19.877-19.877V47.876a8.277 8.277 0 018.277-8.277H71.44a8.277 8.277 0 018.277 8.277v20.25zM45.01 66.862a3.598 3.598 0 003.598 3.598H50a2.206 2.206 0 002.206-2.206c0-.909.57-1.705 1.358-2.157a9.935 9.935 0 002.254-1.768 9.865 9.865 0 002.715-7.369c-.268-5.176-4.538-9.311-9.724-9.417h-.064l-.134.002-.002-.001h-.002c-4.643 0-9.152 3.578-9.848 8.558-.071.544.367 1.002.916 1.002h6.204a.01.01 0 00.009-.01c.026-.187.07-.367.132-.54l.02-.056.031-.078.028-.064a2.715 2.715 0 01.935-1.129l.07-.047.06-.038.044-.026.048-.028a2.808 2.808 0 01.215-.108l.087-.037a2.64 2.64 0 01.136-.052l.06-.02a2.748 2.748 0 01.351-.088l.057-.01.058-.008.067-.008c.042-.005.085-.009.128-.012l.061-.003.063-.003h.068l.002.002.056-.002c1.406.03 2.61 1.19 2.682 2.596a2.735 2.735 0 01-.748 2.039l-.049.049-.06.057a2.722 2.722 0 01-1.806.746l-.076.001h-.676a2.922 2.922 0 00-2.922 2.923v3.712zm3.598 6.113a3.545 3.545 0 00-.001 7.088h.064a3.544 3.544 0 00-.063-7.088z"
            clipRule="evenodd"
          />
        </g>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M48.609 70.446a3.598 3.598 0 01-3.598-3.598v-3.713a2.922 2.922 0 012.922-2.922h.676l.075-.001a2.722 2.722 0 001.808-.746l.06-.058.048-.049a2.735 2.735 0 00.748-2.039c-.072-1.405-1.276-2.566-2.681-2.595l-.057.002-.001-.002h-.07l-.062.002-.061.004a2.816 2.816 0 00-.128.011l-.067.009-.058.008-.057.01a2.791 2.791 0 00-.352.088l-.059.02a2.64 2.64 0 00-.136.051l-.087.038.02-.01a2.742 2.742 0 00-.235.118l-.048.027-.044.026-.06.038-.07.048a2.719 2.719 0 00-.935 1.128l-.028.064-.03.079-.021.056a2.762 2.762 0 00-.132.54.01.01 0 01-.01.01h-6.203c-.55 0-.987-.459-.916-1.003.696-4.979 5.205-8.557 9.848-8.558h.002l.002.001.134-.001h.064c5.186.105 9.456 4.24 9.724 9.417a9.865 9.865 0 01-2.715 7.369 9.935 9.935 0 01-2.254 1.767c-.788.453-1.358 1.249-1.358 2.158A2.206 2.206 0 0150 70.446h-1.392zm0 2.514a3.544 3.544 0 01.063 7.088h-.064v.001a3.545 3.545 0 010-7.089z"
          clipRule="evenodd"
        />
        <defs>
          <filter
            id="filter0_i_258_7616"
            width="44.781"
            height="35.953"
            x="25.218"
            y="6"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="-2" dy="-2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite
              in2="hardAlpha"
              k2="-1"
              k3="1"
              operator="arithmetic"
            />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
            <feBlend in2="shape" result="effect1_innerShadow_258_7616" />
          </filter>
          <filter
            id="filter1_i_258_7616"
            width="64.217"
            height="50.404"
            x="15.5"
            y="37.6"
            colorInterpolationFilters="sRGB"
            filterUnits="userSpaceOnUse"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feColorMatrix
              in="SourceAlpha"
              result="hardAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            />
            <feOffset dx="-2" dy="-2" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite
              in2="hardAlpha"
              k2="-1"
              k3="1"
              operator="arithmetic"
            />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
            <feBlend in2="shape" result="effect1_innerShadow_258_7616" />
          </filter>
          <linearGradient
            id="paint0_linear_258_7616"
            x1="27.218"
            x2="60.284"
            y1="8"
            y2="49.664"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00B8D9" />
            <stop offset="1" stopColor="#006C9C" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_258_7616"
            x1="17.5"
            x2="64.418"
            y1="39.6"
            y2="99.907"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00B8D9" />
            <stop offset="1" stopColor="#006C9C" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default memo(PasswordIcon);
