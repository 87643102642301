import * as Yup from 'yup';

export const WhatsappConfigFormSchema = Yup.object().shape({
  whatsappAccessToken: Yup.string().required('Token de acesso do usuário é obrigatório'),
  phoneNumberId: Yup.string().required('ID do Número de Telefone é obrigatório'),
  whatsappBusinessAccountId: Yup.string().required(
    'ID da conta do whatsapp business é obrigatório'
  ),
  whatsappAppBusinessId: Yup.string().required(
    'ID do aplicativo cadastrado no whatsapp business é obrigatório'
  ),
  activated: Yup.boolean(),
});

export type TWhatsappConfigFormSchema = Yup.InferType<typeof WhatsappConfigFormSchema>;

export const whatsappFormSchemaDefaultValues: TWhatsappConfigFormSchema = {
  whatsappAccessToken: '',
  phoneNumberId: '',
  whatsappBusinessAccountId: '',
  whatsappAppBusinessId: '',
  activated: false,
};
