import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { AuthContext } from './auth-context';
import { setSession, isValidToken } from './utils';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';
import {
  useLoginMutation,
  useCreateUserMutation,
  useForgotEmailMutation,
  LoginMutationVariables,
  usePasswordResetMutation,
  CreateUserMutationVariables,
  ForgotEmailMutationVariables,
  PasswordResetMutationVariables,
} from '../../../graphql/generated';

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

interface IResponsePasswordError {
  message: string;
  code: string;
}

// ----------------------------------------------------------------------
// forgotPassword

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'accessToken';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [createUser] = useCreateUserMutation();
  const [signIn] = useLoginMutation();
  const [forgotEmail] = useForgotEmailMutation();
  const [passwordReset] = usePasswordResetMutation();

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user: {
              // ...user,
              accessToken,
            },
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const login = useCallback(
    async (email: string, password: string) => {
      const data: LoginMutationVariables = {
        input: {
          email,
          password,
        },
      };

      const response = await signIn({ variables: data });

      const token = response?.data?.tokenAuth?.token;
      const user = response?.data?.tokenAuth?.user;

      if (!token || !user) throw new Error('Invalid credentials!');

      setSession(token);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...user,
            token,
          },
        },
      });
    },
    [signIn]
  );

  // REGISTER
  const register = useCallback(
    async (username: string, email: string, password1: string, password2: string) => {
      const data = {
        input: {
          username,
          email,
          lastName: '',
          firstName: '',
          password1,
          password2,
        },
      } as unknown as CreateUserMutationVariables;

      await createUser({ variables: data });
    },
    [createUser]
  );

  // FORGOT PASSWORD
  const forgotPassword = useCallback(
    async (email: string) => {
      const data: ForgotEmailMutationVariables = {
        input: {
          email,
        },
      };

      const response = await forgotEmail({ variables: data });

      const isFailed = !response?.data?.sendPasswordResetEmail?.success;

      if (isFailed) throw new Error('Failed to send email.');
    },
    [forgotEmail]
  );

  // RESET PASSWORD
  const resetPassword = useCallback(
    async (token: string, newPassword1: string, newPassword2: string) => {
      const data: PasswordResetMutationVariables = {
        input: {
          newPassword1,
          newPassword2,
          token,
        },
      };

      const response = await passwordReset({ variables: data });

      const isFailed = !!response?.data?.passwordReset?.errors;

      if (isFailed) {
        const errors = response?.data?.passwordReset?.errors as Record<
          string,
          IResponsePasswordError[]
        >;

        const firstKeyFromError = Object.keys(errors)[0];

        if (firstKeyFromError && errors[firstKeyFromError]) {
          const errorMessage = errors[firstKeyFromError][0].message;

          throw new Error(errorMessage);
        }

        throw new Error('Failed to send email.');
      }
    },
    [passwordReset]
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'apollo',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      register,
      resetPassword,
      forgotPassword,
      logout,
    }),
    [login, logout, register, resetPassword, forgotPassword, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
