import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';

import { RHFMaskTextField } from 'src/components/hook-form';


// ----------------------------------------------------------------------

type CardValueFormProps = {
  enableForm: boolean;
};

// ----------------------------------------------------------------------

export function CardValueForm({ enableForm }: CardValueFormProps) {
  const theme = useTheme()
  return (
    <Stack spacing={1} sx={{ p: 3, borderRadius: 2, bgcolor: theme.palette.grey[200] }}>
      <Stack gap={3}>
        <Stack gap={2}>
          <FormControlLabel
            label="1"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneOneMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                />
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneOneMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="2"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneTwoMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                />
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneTwoMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="3"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneThreeMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                />
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneThreeMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="4"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneFourMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                />
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneFourMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
          <Divider variant="middle" />
        </Stack>
        <Stack gap={2}>
          <FormControlLabel
            label="5"
            labelPlacement="start"
            control={
              <Stack
                width="100%"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{
                  xs: 2,
                  md: 4,
                }}
                mx={2}
              >
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneFiveMin"
                  label="Mínimo"
                  type="text"
                  disabled={!enableForm}
                />
                <RHFMaskTextField
                  formatMask="MONEY"
                  name="value.zoneFiveMax"
                  label="Máximo"
                  type="text"
                  disabled={!enableForm}
                />
              </Stack>
            }
            sx={{
              m: 0,
              width: 1,
              justifyContent: 'space-between',
            }}
          />
        </Stack>
      </Stack>
    </Stack >
  );
}
