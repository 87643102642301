import { useSnackbar } from 'notistack';

import { LoadingButton } from '@mui/lab';
import { MenuList } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { useResponsive } from 'src/hooks/use-responsive';

import {
  GetProfileQuery,
  PermissionUserType,
  useUpdateMyConnectedStoreMutation,
} from 'src/graphql/generated';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export default function ConnectedStorePopover({ profile }: { profile: GetProfileQuery }) {
  const popover = usePopover();

  const [executeUpdateConnectedStore, updateConnectedStoreData] =
    useUpdateMyConnectedStoreMutation();
  const { enqueueSnackbar } = useSnackbar();
  const isUniqueStore = !!profile?.me?.permissions?.length && profile?.me?.permissions?.length <= 1;

  const mdDown = useResponsive('down', 'md');

  const planChoices = {
    FREE: 'Free',
    PRO: 'Pro',
  };

  async function onUpdateConnectedStore(storeId: string) {
    try {
      popover.onClose();
      const { data } = await executeUpdateConnectedStore({ variables: { storeId } });
      if (data?.updateUserConnectedStore?.ok) {
        enqueueSnackbar('Loja alterada com sucesso!');
        window.location.reload();
        return;
      }
      enqueueSnackbar('Erro ao alterar loja!', { variant: 'error' });
    } catch (error) {
      if (error?.message) {
        enqueueSnackbar(error?.message, { variant: 'error' });
        return;
      }
      enqueueSnackbar('Erro ao alterar loja!', { variant: 'error' });
    }
  }

  if (isUniqueStore) {
    return (
      <>
        <Typography variant="subtitle2">
          {profile?.me?.connectedStore?.name?.length! >= 10 && mdDown
            ? `${profile?.me?.connectedStore?.name.substring(0, 10)}...`
            : profile?.me?.connectedStore?.name}
        </Typography>

        {profile?.me?.connectedStore?.isActive && (
          <Label
            color={profile?.me?.connectedStore?.plan === 'FREE' ? 'default' : 'info'}
            sx={{
              height: 22,
              // display: { xs: 'none', [mediaQuery]: 'inline-flex' },
            }}
          >
            {planChoices[profile?.me?.connectedStore?.plan as keyof typeof planChoices]}
          </Label>
        )}

        {!profile?.me?.connectedStore?.isActive && (
          <Label color="default" variant="outlined">
            Inativo
          </Label>
        )}
      </>
    );
  }

  return (
    <>
      <LoadingButton
        sx={{
          py: 0.5,
          gap: { xs: 0.5, md: 1 },
        }}
        onClick={popover.onOpen}
        loading={updateConnectedStoreData.loading}
        disabled={updateConnectedStoreData.loading}
        aria-disabled="true"
      >
        <Typography variant="subtitle2">
          {profile?.me?.connectedStore?.name?.length! >= 10 && mdDown
            ? `${profile?.me?.connectedStore?.name.substring(0, 10)}...`
            : profile?.me?.connectedStore?.name}
        </Typography>

        {profile?.me?.connectedStore?.isActive && (
          <Label
            color={profile?.me?.connectedStore?.plan === 'FREE' ? 'default' : 'info'}
            sx={{
              height: 22,
              // display: { xs: 'none', [mediaQuery]: 'inline-flex' },
            }}
          >
            {planChoices[profile?.me?.connectedStore?.plan as keyof typeof planChoices]}
          </Label>
        )}

        {!profile?.me?.connectedStore?.isActive && (
          <Label color="default" variant="outlined">
            Inativo
          </Label>
        )}
        <Iconify
          width={16}
          icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
          color={popover.open ? 'inherit' : 'default'}
        />
      </LoadingButton>

      <CustomPopover open={popover.open} onClose={popover.onClose} arrow="top-left">
        <MenuList sx={{ width: 240 }}>
          {profile.me?.permissions.map((permission) => (
            <MenuItem
              key={`user-permission-id-${permission.id}`}
              sx={{ p: 1, height: 48 }}
              selected={permission?.store?.id === profile?.me?.connectedStore?.id}
              onClick={() => {
                if (permission?.store?.id === profile?.me?.connectedStore?.id) {
                  popover.onClose();
                  return;
                }
                onUpdateConnectedStore(permission.store.id);
              }}
            >
              <ListItemText
                primary={permission?.store?.name}
                secondary={
                  permission?.userType === PermissionUserType.Admin
                    ? 'Administrador'
                    : 'Funcionário'
                }
              />
              {permission?.store?.isActive && (
                <Label color={permission?.store?.plan === 'FREE' ? 'default' : 'info'}>
                  {planChoices[permission?.store?.plan as keyof typeof planChoices]}
                </Label>
              )}

              {!permission?.store?.isActive && (
                <Label color="default" variant="outlined">
                  Inativo
                </Label>
              )}
            </MenuItem>
          ))}
        </MenuList>
      </CustomPopover>
    </>
  );
}
